










































import { Component, Vue } from "vue-property-decorator";
import OmniSearchBox from "@/components/OmniSearchBox.vue";
import UserInfo from "@/components/UserInfo.vue";
import { readIsManager, readMe } from "@/store/auth/getters";
import { api } from "@/api";
import { readIsAlpha } from "@/store/auth/getters";
import { readIsSuper } from "@/store/auth/getters";
import dayjs from "dayjs";
import { downloadExport } from "@/utils";
import TopBar from "@/components/TopBar.vue";

@Component({
  components: {
    OmniSearchBox,
    UserInfo,
    TopBar,
  },
})
export default class Manage extends Vue {
  created() {
    try {
      if (this.$tours["myTour"] && this.$tours["myTour"].isRunning) {
        if (this.$route.hash === "#tour-next") this.$tours["myTour"].nextStep();
        if (this.$route.hash === "#tour-prev")
          this.$tours["myTour"].previousStep();
      }
    } catch (e) {
      console.info(e);
    }
  }

  public exporting = false;
  public searchInput = null;
  public openDropdown = false;
  public offices = [
    { name: "Hong Kong", code: "HK" },
    { name: "Philippines", code: "PH" },
    { name: "Korea", code: "KR" },
    { name: "Japan", code: "JP" },
    { name: "Indonesia", code: "ID" },
  ];
  public statuses = ["Latest Active", "Latest Inactive"];
  public manageTabs = [
    { label: "Managers", to: "/manage/managers" },
    { label: "Creators", to: "/manage/creators" },
    { label: "Manager Groups", to: "/manage/mapping-groups" },
  ];

  public alphaItems = [
    {
      label: "Manager",
      to: "/manage/managers/create",
    },
    // {
    //   label: "Manager Group",
    //   to: "/manage/mapping-groups/create"
    // },
    {
      label: "Creator",
      to: "/manage/creators/create",
    },
  ];

  public superItems = [
    {
      label: "Creator",
      to: "/manage/creators/create",
    },
    // {
    //   label: "Manager Group",
    //   to: "/manage/mapping-groups/create"
    // }
  ];

  public managerItems = [
    {
      label: "Creator",
      to: "/manage/creators/create",
    },
  ];

  get isAlpha() {
    return readIsAlpha(this.$store);
  }

  get isSuper() {
    return readIsSuper(this.$store);
  }

  get isManager() {
    return readIsManager(this.$store);
  }

  get readonly() {
    return !this.isManager;
  }

  get checkRouteForExport() {
    const routeSplit = this.$route.path.split("/").filter((item) => item);
    return ["managers", "creators", "import"].includes(
      routeSplit[routeSplit.length - 1]
    );
  }

  get showImportButton() {
    return this.isManager && this.$route.path.includes("/creators");
  }

  public toggle(event) {
    this.$refs.menu["toggle"](event);
  }

  public async doExport() {
    try {
      this.exporting = true;
      const currentTab = this.$route.path;
      const tab = currentTab.split("/")[2];
      let currentData = this.$refs.manage["$children"][0]["dataToRender"];
      if (tab == "creators")
        currentData = this.$refs.manage["filteredCreators"];
      const ids = currentData.map((item) => item.id);
      let entity;
      switch (tab) {
        case "managers":
          entity = "managers";
          break;
        case "mapping-groups":
          entity = "mapping_groups";
          break;
        case "creators":
          entity = "creators";
          break;
      }
      const response = await api.exportPage(entity, ids);
      if (response) {
        const date = dayjs().format("YYYY-MM-DD-HH-mm-ss");
        downloadExport(response, `${entity}-export-${date}.csv`);
        this["$toast"].add({
          severity: "success",
          summary: "File exported!",
          detail:
            "Import to Google Sheets or open with a text editor for optimal operations",
          life: 3000,
        });
      } else {
        this["$toast"].add({
          severity: "error",
          summary: "Something went wrong...",
          detail: "Could not export your file",
          life: 3000,
        });
      }
    } catch (e) {
    } finally {
      this.exporting = false;
    }
  }
}
